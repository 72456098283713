import analytics from '@capturi/analytics'
import { useSavedFilter } from '@capturi/api-filters'
import {
  scoringAPI,
  useCreateScore,
  useDeleteScore,
  useScoreDeps,
  useUpdateScore,
} from '@capturi/api-scoring'
import { useScore } from '@capturi/api-scoring/src/scores/useScore'
import {
  PreviewAccessRequestModel,
  PreviewAccessResponse,
  useCurrentUser,
} from '@capturi/core'
import {
  PhoneFilter,
  getAcl,
  getPermissionPreset,
  hasUnsavedChanges,
  toDuration,
  toFilterValues,
  useFilterDefinitions,
} from '@capturi/filters'
import { ResponseError, getErrorMessage } from '@capturi/request'
import {
  PermissionPresetState,
  PreviewAccessWarningText,
  ValidationErrorText,
  usePreviewAccess,
} from '@capturi/sharing'
import { Button, Caption, Description, FormLabel } from '@capturi/ui-components'
import { useConfirm } from '@capturi/use-confirm'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FlexProps,
  FormControl,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, t } from '@lingui/macro'
import { DependentsInfoDialog } from 'components/DependentsInfoDialog'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { MdInfoOutline, MdMessage, MdShare } from 'react-icons/md'
import { useNavigate } from 'react-router'

import ScoreDescriptionItem from '../components/ScoreDescriptionItem'
import ScoringIcon from '../icon'
import { routes } from '../routes'
import { EditableControls } from './components/EditableControls'
import Parameters from './components/Parameters'
import { ScorePermissionDialog } from './components/ScorePermissionDialog'
import IsMaxScoreMessage from './components/isMaxScoreMessage'
import { createFormModel } from './mappers'
import * as scoreParamMappers from './mappers/score-parameter'
import { ScoreFormModel, ScoreParameterFormModel } from './types'
import { validationSchema } from './validationSchema'

const showDevtools = false

type ScoreEditorProviderProps = {
  initialValues?: (
    createFormModelFn: typeof createFormModel,
  ) => Partial<ScoreFormModel>
  children?: React.ReactNode
}

export const ScoreEditorProvider: React.FC<ScoreEditorProviderProps> = ({
  initialValues,
  children,
}) => {
  const initialFormValues = React.useRef(
    initialValues?.(createFormModel) ?? createFormModel(),
  )

  const formMethods = useForm<ScoreFormModel>({
    defaultValues: initialFormValues.current,
    resolver: yupResolver(validationSchema),
  })

  return (
    <FormProvider {...formMethods}>
      {children}
      {showDevtools && process.env.NODE_ENV === 'development' && (
        <DevTool control={formMethods.control} placement="top-left" />
      )}
    </FormProvider>
  )
}

type ScoreEditorProps = {
  uid?: string
  created?: Date
  createdByUserUid?: string
  updated?: Date
  updatedByUserUid?: string
}

function getPreviewAccessWarningText(
  previewAccessResponse: PreviewAccessResponse | undefined,
): string | undefined {
  switch (previewAccessResponse) {
    case 'NoAccess':
      return t`*You will no longer be able to view the score when you apply`
    case 'Editable':
      return undefined
    case 'Viewable':
      return t`*You will no longer be able to edit the score when you apply because you set your own permission to “viewer”`
  }
}

export const ScoreEditor: React.FC<ScoreEditorProps> = ({ uid }) => {
  const [openScoreDependentsDialog] = useModal(DependentsInfoDialog)
  const currentUser = useCurrentUser()

  const filterDefinitions = useFilterDefinitions(currentUser, {
    scores: {
      inactive: true,
    },
  })
  const { mutate: updateScore } = useUpdateScore()
  const { mutate: createScore } = useCreateScore()
  const { mutate: deleteScore } = useDeleteScore()
  const { data: dependents } = useScoreDeps(uid)

  const hasDependents = dependents?.length

  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const confirm = useConfirm()

  const [shouldDirtyPoll, setShouldDirtyPoll] = React.useState(false)
  const { data: score } = useScore(uid, shouldDirtyPoll ? 7000 : 0)

  React.useEffect(() => {
    if (!score) return
    if (score.isProcessing) {
      if (!shouldDirtyPoll) {
        setShouldDirtyPoll(true)
      }
    } else {
      if (shouldDirtyPoll) {
        setShouldDirtyPoll(false)
      }
    }
  }, [score, shouldDirtyPoll])
  const toast = useToast()

  const closeEditor = (scoreUid?: string, replaceHistory = false): void => {
    navigate(routes.main(scoreUid), { replace: replaceHistory })
  }

  const { data: savedFilter } = useSavedFilter({
    uid: score?.savedFilterGroupUid,
  })

  const [state, _setState] = React.useState<Omit<ScoreFormModel, 'parameters'>>(
    () => ({
      uid: uid ?? undefined,
      name: score?.name ?? '',
      description: score?.description ?? '',
      filters: score?.filters ?? null,
      goal: uid
        ? score?.goal ?? null
        : {
            min: 5,
            max: undefined,
          },
      segmentation: {
        channel: 'phone',
        savedFilter: savedFilter,
        values: savedFilter?.values
          ? toFilterValues(savedFilter?.values ?? {})
          : score?.filters ?? {},
      },
      savedFilterGroupUid: score?.savedFilterGroupUid ?? null,
      permissionPreset: score?.permissionPreset ?? 'Private',
      permissionPresetUserUid: score?.permissionPresetUserUid ?? null,
      acl: score?.acl ?? [],
      folderUid: score?.folderUid ?? undefined,
    }),
  )

  const setState = React.useCallback(
    (partialState: Partial<ScoreFormModel>): void => {
      _setState((state) => ({
        ...state,
        ...partialState,
        acl: getAcl(partialState, state, currentUser.id),
        permissionPresetUserUid: getPermissionPreset(
          partialState,
          state,
          currentUser.id,
        ),
      }))
    },
    [currentUser.id],
  )

  const [parameters, setParameters] = React.useState<ScoreParameterFormModel[]>(
    (score?.parameters || [])?.map(scoreParamMappers.toFormModel),
  )

  const onUpdateScore = (): void => {
    const mappedParams = parameters.map(scoreParamMappers.toRequestModel)
    try {
      if (score) {
        const initialParamsMapped = (score.parameters || [])?.map(
          scoreParamMappers.toFormModel,
        )
        const areParamsEqual = isEqual(initialParamsMapped, parameters)
        const updatedKeys = Object.keys(state).filter((key) => {
          if (key === 'segmentation') return false
          type StateWithoutSegmentation = Omit<typeof state, 'segmentation'>
          const keyWithType = key as keyof StateWithoutSegmentation
          return !isEqual(score[keyWithType], state[keyWithType])
        })
        updateScore(
          {
            uid: score.uid,
            score: {
              ...pick(state, updatedKeys),
              parameters: areParamsEqual ? undefined : mappedParams,
            },
          },
          {
            onError: (error) => {
              if (error instanceof ResponseError) {
                toast({
                  title: t`An error occurred. Please try again.`,
                  description: error.message,
                  status: 'error',
                })
              }
            },
            onSuccess: (score) => {
              analytics.event('scoreEditor_score_updated')
              toast({
                title: t`"${score.name}" updated`,
                status: 'success',
              })
              closeEditor(score.uid)
            },
          },
        )
      } else {
        createScore(
          {
            ...omit(state, 'segmentation'),
            parameters: mappedParams,
          },
          {
            onError: (error) => {
              if (error instanceof ResponseError) {
                toast({
                  title: t`An error occurred. Please try again.`,
                  description: error.message,
                  status: 'error',
                })
              }
            },
            onSuccess: (score) => {
              analytics.event('scoreEditor_score_created')
              toast({
                title: t`"${score.name}" created`,
                status: 'success',
              })
              closeEditor(score.uid)
            },
          },
        )
      }
    } catch (_error) {
      toast({
        title: t`This is not great, something went wrong`,
        description: '...',
        status: 'error',
      })
    }
  }

  const handleDeleteScore = async (): Promise<void> => {
    try {
      if (score) {
        deleteScore(score.uid, {
          onSuccess: () => {
            analytics.event('scoreEditor_score_deleted')
            toast({
              title: t`Score was successfully deleted.`,
              status: 'success',
            })
            closeEditor()
          },
          onError: (error) => {
            const errorMsg = getErrorMessage(error, {
              409: t`It is not possible to delete the score as recent changes are still being processed`,
            })
            if (errorMsg) {
              toast({
                title: t`Ouch, we could not delete the score`,
                description: errorMsg,
                status: 'error',
              })
            } else if (error instanceof ResponseError) {
              toast({
                title: t`An error occurred. Please try again.`,
                description: error.message,
                status: 'error',
              })
            }
          },
        })
      }
    } catch (_error) {
      toast({
        title: t`Something went wrong while deleting score. Please try again...`,
        description: '...',
        status: 'error',
      })
    }
  }

  const onDeleteScore = async (): Promise<void> => {
    if (hasDependents) {
      openScoreDependentsDialog({
        heading: t`The score cannot be deleted`,
        description: t`Remove references to this score from the following segments and dashboards before it can be deleted.`,
        dependents,
      })
      return
    }

    try {
      await confirm({
        title: t`Are you sure?`,
        description: t`Are you sure, you want to delete the score "${score?.name}"?`,
        cancelText: t`Cancel`,
        confirmText: t`Delete`,
      })
      handleDeleteScore()
    } catch {
      // confirm dialog cancelled
      return
    }
  }

  const previewAccessRequestModel: PreviewAccessRequestModel = {
    uid: uid,
    permissionPreset: state.permissionPreset,
    permissionPresetUserUid: state.permissionPresetUserUid,
    acl: state.acl,
    folderUid: state.folderUid,
  }
  const { previewAccessResponse, validationError } = usePreviewAccess({
    previewAccessRequestModel,
    createPreviewAccessRequest: scoringAPI.previewAccess,
  })

  const tooltipLabel = !parameters.length
    ? t`A minimum of one criteria is required to save the score`
    : !state.name
      ? t`Name is required to save the score`
      : ''

  const deleteTooltip = score?.isProcessing
    ? t`It is not possible to delete the score as recent changes are still being processed`
    : undefined

  const modalHeader = t`View & edit`
  return (
    <>
      <Flex flexDirection={{ base: 'column', md: 'row-reverse' }}>
        <Box flex={1}>
          <Flex direction="column">
            {score && (
              <>
                <Description fontSize="sm" textAlign="right" mt={2}>
                  <ScoreDescriptionItem
                    userUid={score.createdByUserUid}
                    date={score.created}
                    label={t`Created`}
                  />
                </Description>

                <Description fontSize="sm" textAlign="right">
                  <ScoreDescriptionItem
                    userUid={score.updatedByUserUid}
                    date={score.updated}
                    label={t`Updated`}
                  />
                </Description>
              </>
            )}
          </Flex>
        </Box>
        <Box mr={4}>
          <Editable
            fontSize="2xl"
            display="flex"
            alignItems="center"
            placeholder={t`Name of score`}
            value={state.name}
            startWithEditView={!state.name.length}
          >
            <EditablePreview
              color={!state.name.length ? 'gray.400' : 'inherit'}
              whiteSpace="nowrap"
            />
            <EditableInput
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setState({ name: e.target.value })
              }}
              value={state.name}
              pl={2}
            />
            <EditableControls ml={4} />
          </Editable>
        </Box>
      </Flex>

      <Flex
        fontWeight="500"
        alignItems="center"
        justifyContent="start"
        mb="2"
        mt={8}
      >
        <FormControl pr={4} w="initial">
          <FormLabel
            id="description"
            leftIcon={<MdMessage />}
            rightIcon={
              <Tooltip
                label={t`This description will displayed to users on the analysis page`}
              >
                <span>
                  <MdInfoOutline />
                </span>
              </Tooltip>
            }
          >
            <Trans>Description</Trans>
          </FormLabel>
          <Textarea
            placeholder={t`Description`}
            minW="32em"
            minH="5em"
            maxW="36em"
            onChange={(e) => setState({ description: e.target.value })}
            value={state.description}
          />
        </FormControl>
        <Divider
          orientation="vertical"
          height="150px"
          backgroundColor="gray.200"
          mr={4}
        />
        <Box w="100%">
          <Box fontWeight="500" display="flex" alignItems="center" mb="2">
            <MdShare />
            <Text mx={2}>
              <Trans>Sharing and edit rights</Trans>
            </Text>
          </Box>
          <PermissionPresetState
            permissionPreset={state.permissionPreset}
            localizedEntityName={'scores'}
            onOpen={onOpen}
            acl={state.acl}
          />
          <PreviewAccessWarningText
            warningText={getPreviewAccessWarningText(previewAccessResponse)}
          />
          <ValidationErrorText validationError={validationError} />
        </Box>
        <FormControl>
          {isOpen && (
            <ScorePermissionDialog
              initialPermissionPreset={state.permissionPreset}
              initialAcl={state?.acl ?? null}
              //Update when folders are made
              folderPermissionPreset={undefined}
              permissionPresetUserUid={state.permissionPresetUserUid}
              //Update when folders are made
              folderAcl={undefined}
              header={modalHeader}
              isOpen={isOpen}
              onClose={onClose}
              onSave={(model) => {
                setState({ acl: model.acl })
                setState({ permissionPreset: model.permissionPreset })
                setState({
                  permissionPresetUserUid: model.permissionPresetUserUid,
                })
              }}
            />
          )}
        </FormControl>
      </Flex>

      <FormControl mt={8}>
        <FormLabel id="score" leftIcon={<ScoringIcon />} data-stonly="score">
          <Trans>Score</Trans>
        </FormLabel>
        <ContentBox mt={4}>
          <Box>
            <FormLabel mb={0}>
              <Trans>Data basis</Trans>
            </FormLabel>
            <Caption color="textMuted">
              <Trans>
                Select the conversations this score configuration should be
                processed on.
              </Trans>
            </Caption>
            <Box>
              <Controller<ScoreFormModel, 'segmentation'>
                name="segmentation"
                render={({ field }) => (
                  <PhoneFilter
                    filterDefinitions={filterDefinitions}
                    state={{
                      channel: 'phone',
                      ...state.segmentation,
                      values: {
                        ...state.segmentation?.values,
                        duration: toDuration(
                          state.segmentation?.values.duration?.min,
                          state.segmentation?.values.duration?.max,
                        ),
                      },
                    }}
                    onStateChange={(filter) => {
                      if (hasUnsavedChanges(filter)) {
                        // Reset the reference to the saved filter if there are unsaved changes.
                        filter.savedFilter = undefined
                      }

                      field.onChange(filter)
                      if (filter.savedFilter) {
                        setState({
                          savedFilterGroupUid: filter.savedFilter?.uid,
                          segmentation: {
                            channel: 'phone',
                            savedFilter: filter.savedFilter,
                            values: filter.values,
                          },
                          filters: null,
                        })
                      } else if (filter.values) {
                        setState({
                          filters: filter.values,
                          segmentation: {
                            channel: 'phone',
                            savedFilter: undefined,
                            values: filter.values,
                          },
                          savedFilterGroupUid: null,
                        })
                      }
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <Parameters
            goal={state.goal}
            params={parameters}
            onGoalChange={(state) => setState({ goal: state.goal })}
            onParameterChange={setParameters}
          />
        </ContentBox>
      </FormControl>
      <IsMaxScoreMessage />
      <Flex as="footer" mt={8} justifyContent={score ? 'initial' : 'end'}>
        {score && (
          <Tooltip label={deleteTooltip} placement="top">
            <Button
              mr="auto"
              colorScheme="red"
              size="md"
              onClick={() => onDeleteScore()}
              isDisabled={score?.isProcessing}
            >
              <Trans>Delete</Trans>
            </Button>
          </Tooltip>
        )}
        <Button secondary size="md" mr={2} onClick={() => closeEditor()}>
          <Trans>Cancel</Trans>
        </Button>
        <Tooltip
          label={tooltipLabel}
          isDisabled={parameters.length > 0 && state.name.length > 0}
          hasArrow
          placement="top"
        >
          <Button
            isDisabled={parameters.length < 1 || state.name.length < 1}
            size="md"
            primary
            onClick={onUpdateScore}
          >
            <Trans>Save score</Trans>
          </Button>
        </Tooltip>
      </Flex>
    </>
  )
}

const ContentBox: React.FC<FlexProps> = (props) => (
  <Flex
    direction="column"
    border="1px"
    borderColor="gray.300"
    borderRadius="md"
    p={4}
    {...props}
  />
)
